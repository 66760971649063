<template>
  <v-container class="mt-5">
    <v-data-table
      :headers="headers"
      :items="periods"
      sort-by="code"
      class="mt-5 elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat class="mb-10">
          <v-form>
            <v-select
              v-model="year"
              class="mt-7 ml-2"
              dense
              :items="years"
              label="Year"
            />
          </v-form>
          <v-spacer />
          <v-btn class="primary" @click="getP9Report">
            <v-icon>mdi-file-document-outline</v-icon>
            Download P9 Report
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          :to="{
            name: 'Payslip View',
            params: { code: encodeRoute(item.startingDate) },
          }"
          color="primary"
          small
        >
          Open
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data> No Appraisal Reviews </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";

export default {
  name: "PayslipList",
  mixins: [RouterMixin, FileMixin],
  data: () => ({
    year: "",
    headers: [
      { text: "Period", align: "start", sortable: false, value: "name" },
      { text: "Start Date", value: "startingDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("payslip/getPeriods");
      v.$store.dispatch("Dashboard/getp9Report", {
        empNo: v.userData.employee,
      });
    });
  },

  computed: {
    periods() {
      return this.$store.getters["payslip/periods"]
        .filter((p) => {
          return this.year === ""
            ? true
            : moment(p.startingDate).year() === this.year;
        })
        .sort((a, b) => {
          return moment(b.startingDate).diff(moment(a.startingDate));
        });
    },

    userData: function () {
      return this.$store.getters["auth/user"];
    },

    p9Report() {
      return this.$store.getters["Dashboard/p9Report"];
    },

    years() {
      const employee = this.$store.getters["auth/user"].profile;
      const start = moment(employee.employment_date).year();
      const end = parseInt(moment().year()) - parseInt(start);

      const years = [];

      for (let i = 0; i <= end; i++) {
        years.push(start + i);
      }

      return years.reverse();
    },
  },

  watch: {
    periods: {
      handler: function () {
        if (this.periods) {
          this.periods.forEach((p) => {
            if (p.current) {
              this.year = moment(p.startingDate).year();
            }
          });
        }
      },
      immediate: true,
    },
    p9Report: {
      deep: true,
      handler(val) {
        this.downloadFile(val?.base64);
      },
    },
  },
  methods: {
    getP9Report() {
      this.$store.commit("loader/SET_LOADING", true, { root: true });
      this.$store
        .dispatch("Dashboard/getp9Report", {
          empNo: this.userData.employee,
          period: this.year,
        })
        .then((res) => {
          console.log(res);
          this.$store.commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          this.$store.commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error?.response?.data?.message);
        });
    },
  },
};
</script>

<style scoped></style>
