import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-5"},[_c(VDataTable,{staticClass:"mt-5 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.periods,"sort-by":"code"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{staticClass:"mb-10",attrs:{"flat":""}},[_c(VForm,[_c(VSelect,{staticClass:"mt-7 ml-2",attrs:{"dense":"","items":_vm.years,"label":"Year"},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c(VSpacer),_c(VBtn,{staticClass:"primary",on:{"click":_vm.getP9Report}},[_c(VIcon,[_vm._v("mdi-file-document-outline")]),_vm._v(" Download P9 Report ")],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"to":{
          name: 'Payslip View',
          params: { code: _vm.encodeRoute(item.startingDate) },
        },"color":"primary","small":""}},[_vm._v(" Open "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No Appraisal Reviews ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }